<template>
    <v-container fluid>
        <v-form @submit.prevent="savePartner">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div class="d-flex">
                    <div class="mb-5">
                        <v-btn color="primary" :to="{name: 'partner'}" class="back-btn" link large>
                            <v-icon>mdi-less-than</v-icon>
                        </v-btn>
                    </div>
                    <div>
                        <v-subheader class="headline">{{ $t('partner_profile') }}</v-subheader>
                    </div>
                </div>
                <v-row class="mt-2">
                    <v-col cols="12" sm="3" md="2" class="pt-0">
                        <make-avatar v-model="photo" :aspect-ratio="(1/1)"/>
                    </v-col>
                    <v-col cols="12" sm="9" md="10">
                        <v-row>
                            <v-col class="py-0" cols="12" sm="12">
                                <ValidationProvider ref="partner" rules="required|min:2|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="partner" type="text"
                                                  :error="!valid" :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-account"
                                                  :label="$t('partner_name')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="phone" rules="required|phone"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="phoneRaw" type="tel"
                                                  v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                                  :error-messages="errors"
                                                  :disabled="loading" prepend-icon="mdi-phone"
                                                  :label="$t('phone')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary"
                                                  clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="email" rules="required|email"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="email" type="email"
                                                  :error-messages="errors" :error="!valid"
                                                  :disabled="loading" prepend-icon="mdi-email"
                                                  label="Email"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="experience" rules="numeric|min:1"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="experience" type="numeric"
                                                  :error-messages="errors" :error="!valid"
                                                  :disabled="loading" prepend-icon="mdi-numeric"
                                                  :label="$t('experience')"
                                                  outlined
                                                  rounded
                                                  dense
                                                  class="rounded-lg"
                                                  color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col class="py-0" cols="12" sm="6">
                                <ValidationProvider ref="sex" rules="required"
                                                    v-slot="{ errors, valid }">
                                    <v-select v-model="sex" :error="!valid" :error-messages="errors"
                                              :disabled="loadingSexes"
                                              :items="sexItems" item-text="name" item-value="id"
                                              prepend-icon="mdi-gender-male-female" color="primary"
                                              :label="$t('sex')"
                                              outlined
                                              rounded
                                              dense
                                              class="rounded-lg"
                                              clearable
                                    ></v-select>
                                </ValidationProvider>
                            </v-col>

                        </v-row>
                    </v-col>
                </v-row>
                <v-row class="pt-2">
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="doctor_category" rules="required"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="doctor_category" :error="!valid" :error-messages="errors"
                                      :disabled="loadingDoctorCategories"
                                      :items="doctorCategoryItems" item-text="name" item-value="id"
                                      prepend-icon="mdi-shield-account-outline" color="primary"
                                      :label="$t('doctor_category')"
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      clearable
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="specialty" rules="required"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="specialty" :error="!valid" :error-messages="errors"
                                      :disabled="loadingSpecialties"
                                      :items="specialtyItems" item-text="name" item-value="id"
                                      prepend-icon="mdi-card-account-details-outline" color="primary"
                                      :label="$t('specialty')"
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      clearable
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="4">
                        <ValidationProvider ref="certificate" rules="required|min:1"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="certificate" type="numeric"
                                          :error-messages="errors" :error="!valid"
                                          :disabled="loading" prepend-icon="mdi-certificate-outline"
                                          :label="$t('certificate')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>

                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="city" rules="required"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="city" :error="!valid" :error-messages="errors"
                                      :disabled="loadingCities"
                                      :items="cityItems" item-text="name" item-value="id"
                                      prepend-icon="mdi-city-variant" color="primary"
                                      :label="$t('city')"
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      clearable
                            ></v-select>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="company" rules="required|min:1"
                                            v-slot="{ errors, valid }">
                            <v-select v-model="company" :disabled="loadingCompanies"
                                      :items="companyItems" :error-messages="errors"
                                      prepend-icon="mdi-home-plus-outline" :error="!valid"
                                      color="primary" item-text="name" item-value="id"
                                      :label="$t('clinic')"
                                      outlined
                                      rounded
                                      dense
                                      class="rounded-lg"
                                      clearable></v-select>
                        </ValidationProvider>
                    </v-col>


                    <v-col class="py-0" v-if="can(['administrator', 'manager'])" cols="12">
                        <ValidationProvider ref="notes" rules="min:3|max:65535"
                                            v-slot="{ errors, valid }">
                            <v-textarea v-model="notes" type="text" :error-messages="errors"
                                        :disabled="loading" :label="$t('notes')" rows="5"
                                        color="primary" auto-grow clearable
                                        outlined
                                        rounded
                                        dense
                                        class="rounded-lg"
                            >
                            </v-textarea>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row class="pt-0">
                    <v-col class="pt-0" cols="12">
                        <h3 class="headline">{{ $t('partner_access_password') }}</h3>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="password" :rules="form_required + 'min:8'"
                                            vid="password" v-slot="{ errors, valid }">
                            <v-text-field v-model="password" :error="!valid && create" :disabled="loading"
                                          :append-icon="passwordHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                          :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                          @click:append="() => (passwordHidden = !passwordHidden)"
                                          prepend-icon="mdi-lock" :label="$t('enter_password')"
                                          color="primary" clearable required
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                            ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="6">
                        <ValidationProvider ref="confirmation_password"
                                            rules="required_if:password|confirmed:password"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="password_confirmation" :error="!valid" :disabled="loading"
                                          :append-icon="passwordConfirmHidden ? 'mdi-eye-off' : 'mdi-eye'"
                                          :type="passwordHidden ? 'password' : 'text'" :error-messages="errors"
                                          @click:append="() => (passwordConfirmHidden = !passwordConfirmHidden)"
                                          prepend-icon="mdi-lock" :label="$t('repeat_password')"
                                          color="primary" clearable required
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                            ></v-text-field>
                        </ValidationProvider>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col class="py-0 d-flex justify-start" cols="12" sm="4">
                        <v-switch v-model="deleted" :disabled="loading"
                                  :label="deleted ? $t('partner_deleted') : $t('partner_remove')"
                                  @change="changeDeleted()" color="red"></v-switch>
                    </v-col>
                    <v-col class="py-0 d-flex justify-start justify-sm-center" cols="12" sm="4">
                        <v-switch v-model="moderated" :disabled="loading || deleted"
                                  :label="$t('active')"
                                  color="primary"></v-switch>
                    </v-col>
                    <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="4">
                        <v-switch v-model="duty" :disabled="loading || deleted"
                                  :label="$t('duty')"
                                  color="primary"></v-switch>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="d-flex">

                        <v-progress-linear v-if="!$vuetify.breakpoint.xsOnly" v-model="progress"
                                           :active="loading" class="mx-2 mt-5"></v-progress-linear>
                        <v-spacer   v-if="!$vuetify.breakpoint.xsOnly"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xsOnly" color="primary">
                            {{ $t('save') }}
                        </v-btn>

                    </v-col>
                </v-row>

            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'
import MakeAvatar from '../components/MakeAvatar.vue'
import {mapGetters} from "vuex"
import debounce from "lodash/debounce"

export default {
    name: 'PartnerForm',
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver,
        MakeAvatar,
    },
    inject: ['forceRerender'],
    data() {
        return {
            progress: 0,
            create: false,
            phoneRaw: null,
            partner: null,
            email: null,
            notes: null,
            photo: null,
            active: false,
            moderated: false,
            deleted: false,
            password: null,
            passwordHidden: true,
            password_confirmation: null,
            passwordConfirmHidden: true,
            loading: false,
            loadingCompanies: false,
            company: null,
            companyItems: [],
            loadingSpecialties: false,
            specialty: null,
            specialtyItems: [],
            loadingSexes: false,
            sex: null,
            sexItems: [],
            loadingCities: false,
            city: null,
            cityItems: [],
            loadingDoctorCategories: false,
            doctor_category: null,
            doctorCategoryItems: [],
            certificate: null,
            duty: false,
            experience: null,


        }
    },
    computed: {
        ...mapGetters(['lang', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        form_required: function () {
            if (this.create) {
                return 'required|'
            }
            return ''
        },
    },
    watch: {
        password(val) {
            if (val && val !== this.password_confirmation) {
                this.$refs.confirmation_password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.confirmation_password')})
                ])
            }
        },
        password_confirmation(val) {
            if (val && val !== this.password) {
                this.$refs.password.setErrors([
                    this.$t('validations.rules.confirmed', {'_field_': this.$t('validations.fields.password')})
                ])
            } else {
                this.$refs.password.reset()
            }
        },
    },
    mounted() {
        this.getCompanies();
        this.getSexes();
        this.getCities();
        this.getDoctorCategories();
        this.getSpecialties()
        this.checkCreate();
    },
    methods: {
        checkCreate() {
            if (this.$route.name === 'partner.create') {
                this.create = true
            } else if (this.$route.name === 'profile' && this.$auth.check()) {
                if (this.$auth.user().id) {
                    this.getPartner(this.$auth.user().id);
                }
            } else {
                if (this.$route.params.id) {
                    this.getPartner(this.$route.params.id);
                }
            }
        },
        async getCompanies() {

            this.loadingCompanies = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/company", {
                    params: params,
                })
                .then(res => {
                    this.companyItems = res.body.data;
                })
                .catch(err => {
                    this.companyItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_companies'))
                })
                .finally(end => {
                    this.loadingCompanies = false
                })

        },
        async getSexes() {

            this.loadingSexes = true
            let params = {};

            await this.$http
                .get("admin/sex", {
                    params: params,
                })
                .then(res => {
                    this.sexItems = res.body.data;
                })
                .catch(err => {
                    this.sexItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_sexes'))
                })
                .finally(end => {
                    this.loadingSexes = false
                })

        },
        async getCities() {

            this.loadingCities = true
            let params = {};

            await this.$http
                .get("admin/city", {
                    params: params,
                })
                .then(res => {
                    this.cityItems = res.body.data;
                })
                .catch(err => {
                    this.cityItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_cities'))
                })
                .finally(end => {
                    this.loadingCities = false
                })

        },
        async getDoctorCategories() {

            this.loadingDoctorCategories = true
            let params = {};

            await this.$http
                .get("admin/doctor_category", {
                    params: params,
                })
                .then(res => {
                    this.doctorCategoryItems = res.body.data;
                })
                .catch(err => {
                    this.doctorCategoryItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_doctor_categories'))
                })
                .finally(end => {
                    this.loadingDoctorCategories = false
                })

        },
        async getSpecialties() {

            this.loadingSpecialties = true
            let params = {};
            params.filter = 'search'

            await this.$http
                .get("admin/specialty", {
                    params: params,
                })
                .then(res => {
                    this.specialtyItems = res.body.data;
                })
                .catch(err => {
                    this.specialtyItems = []
                    this.$toastr.error(this.$t('failed_to_get_list_specialties'))
                })
                .finally(end => {
                    this.loadingSpecialties = false
                })

        },
        async getPartner(id) {
            var _this = this;
            this.progress = 0
            this.loading = true
            this.id = id
            await this.$http
                .get(`admin/partner/${id}`, {
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.partner = res.body.data.name
                    this.phoneRaw = res.body.data.phone
                    this.email = res.body.data.email
                    this.photo = res.body.data.photo
                    this.active = res.body.data.active
                    this.moderated = res.body.data.moderated
                    this.deleted = res.body.data.deleted
                    this.notes = res.body.data.notes
                    this.company = res.body.data.company.id
                    this.specialty = res.body.data.specialty.id
                    this.doctor_category = res.body.data.doctor_category.id
                    this.sex = res.body.data.sex.id
                    this.city = res.body.data.city.id
                    this.certificate = res.body.data.certificate
                    this.experience = res.body.data.experience
                    this.duty = res.body.data.duty


                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_partner'));
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async savePartner() {
            var _this = this;
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.partner) {
                formData.append('partner', this.partner)
            }
            if (this.phone) {
                formData.append('phone', this.phone)
            }
            if (this.email) {
                formData.append('email', this.email)
            }
            if (this.password) {
                formData.append('password', this.password)
            }
            if (this.notes) {
                formData.append('notes', this.notes)
            }
            if (this.active) {
                formData.append('active', 1)
            }
            if (this.moderated) {
                formData.append('moderated', 1)
            }
            if (this.deleted) {
                formData.append('deleted', 1)
            }
            if (this.duty) {
                formData.append('duty', 1)
            }
            if (this.experience) {
                formData.append('experience', this.experience)
            }
            if (this.certificate) {
                formData.append('certificate', this.certificate)
            }
            if (this.company) {
                if (this.company.id) {
                    formData.append('company', this.company.id)
                } else {
                    formData.append('company', this.company)
                }
            }
            if (this.specialty) {
                if (this.specialty.id) {
                    formData.append('specialty', this.specialty.id)
                } else {
                    formData.append('specialty', this.specialty)
                }
            }

            if (this.doctor_category) {
                if (this.doctor_category.id) {
                    formData.append('doctor_category', this.doctor_category.id)
                } else {
                    formData.append('doctor_category', this.doctor_category)
                }
            }

            if (this.sex) {
                if (this.sex.id) {
                    formData.append('sex', this.sex.id)
                } else {
                    formData.append('sex', this.sex)
                }
            }
            if (this.city) {
                if (this.city.id) {
                    formData.append('city', this.city.id)
                } else {
                    formData.append('city', this.city)
                }
            }

            if (this.photo) {
                if (this.photo.length > 250) {
                    var mimeType = this.getMimeType(this.photo);
                    var blob = this.dataURL64toBlob(this.photo);
                    if (mimeType && blob) {
                        formData.append('photo', blob, mimeType);
                    }
                } else {
                    formData.append('photo', this.photo);
                }
            }
            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/partner/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('partner_has_been_updated'));
                        //this.forceRerender();
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('partner_has_not_been_updated'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/partner', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('partner_has_been_added'));
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.$router.push({
                                name: 'partner.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({name: 'partner'})
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('partner_has_not_been_added'));
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        },
        changeDeleted(){
            if (this.deleted){
                this.moderated = false;
                this.duty = false;
            }
            else{
                this.moderated = true;
            }
        }
    }
}
</script>
