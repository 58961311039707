<template>
    <v-container fluid>
        <v-form @submit.prevent="getPartners('search')">
            <v-btn v-show="can(['administrator']) && $vuetify.breakpoint.xsOnly" :to="{name: 'partner.create'}"
                   color="indigo" dark fab
                   fixed top right class="v-btn--add-form-top">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <div class="d-flex pb-2">
                    <div class="mr-auto">
                        <v-subheader class="headline px-0 mx-0">
                            {{ $t('search_doctor') }}
                            <v-btn v-if="$vuetify.breakpoint.xsOnly" class="ml-1" text icon @click="toggleFilter">
                                <v-icon color="indigo" small>mdi-cog</v-icon>
                            </v-btn>
                        </v-subheader>
                    </div>
                    <div v-if="can(['administrator']) && !$vuetify.breakpoint.xsOnly" cols="12" sm="4"
                         class="text-right">
                        <v-btn :to="{name: 'partner.create'}" color="indigo" dark>{{ $t('create') }}
                        </v-btn>
                    </div>
                </div>
                <v-row v-if="!$vuetify.breakpoint.xsOnly || filter">
                    <v-col class="pb-0" cols="12" sm="12">
                        <ValidationProvider ref="partner" rules="min:1|max:255"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="partner" type="text" :error-messages="errors"
                                          :disabled="loading"
                                          :label="$t('doctor_name')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary"
                                          clearable></v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="5">
                        <ValidationProvider ref="phone" rules="phone"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="phoneRaw" type="tel"
                                          v-mask="['+# (###) ###-##-##', '+## (###) ###-##-##', '+### (###) ###-##-##']"
                                          :error-messages="errors" :disabled="loading"
                                          :label="$t('phone')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="5">
                        <ValidationProvider ref="email" rules="email"
                                            v-slot="{ errors, valid }">
                            <v-text-field v-model="email" type="email" :error-messages="errors"
                                          :disabled="loading"
                                          :label="$t('email')"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          color="primary" clearable>
                            </v-text-field>
                        </ValidationProvider>
                    </v-col>
                    <v-col class="py-0" cols="12" sm="2">
                        <v-select v-model="moderated" :items="activeItems" :disabled="loading"
                                  item-text="name" item-value="id"
                                  outlined
                                  rounded
                                  dense
                                  class="rounded-lg"
                                  :label="$t('active')"></v-select>
                    </v-col>
                    <v-col class="py-0" cols="12">
                        <div class="d-flex">
                            <div class="mr-auto">
                                <v-select v-if="$vuetify.breakpoint.xsOnly" :value="perPage" :items="perPageItems"
                                          :disabled="loading"
                                          :label="$t('items_per_page')"
                                          @input="perPage = options.itemsPerPage = Number($event)"
                                          outlined
                                          rounded
                                          dense
                                          class="rounded-lg"
                                          persistent-hint
                                          hide-details></v-select>
                            </div>
                            <div>
                                <v-btn type="submit" :disabled="invalid || loading" :block="$vuetify.breakpoint.xsOnly"
                                       color="primary">{{ $t('search') }}
                                </v-btn>
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </ValidationObserver>
        </v-form>


        <v-row class="pt-5">
            <v-col>
                <v-data-table :headers="filteredHeaders" :items="partners" :options.sync="options" :page.sync="page"
                              :items-per-page="perPage" :server-items-length="totalPartners"
                              :sort-by.sync="sortBy" :sort-desc.sync="sortDir" :loading="loading" :locale="lang"
                              @page-count="pageCount = options.pageCount = Number($event)"
                              :loading-text="$t('loading_please_wait')" class="elevation-1"
                              hide-default-footer>
                    <template v-slot:item.name="{ item }">
                        <div :class="item.deleted ? 'red--text' : ''">
                            {{ item.name }}
                            {{ item.deleted ? ' (' + $t('deleted') + ')' : '' }}
                        </div>
                    </template>
                    <template v-slot:item.moderated="{ item }">
                        {{ item.moderated ? $t('yes') : $t('no') }}
                    </template>
                    <template v-slot:item.duty="{ item }">
                        <v-edit-dialog :return-value.sync="item.duty" large persistent
                                       @save="setDuty(item)" :save-text="$t('save')"
                                       :cancel-text="$t('cancel')">
                            <div class="cursor-pointer">{{ item.duty ? $t('yes') : $t('no') }}</div>
                            <template v-slot:input>
                                <v-select v-model="item.duty" :items="itemYN" item-text="name"
                                          item-value="id"
                                          outlined
                                          solo
                                          flat
                                          dense
                                          :height="$vuetify.breakpoint.xsOnly ? 45 : 35"
                                          color="primary"
                                          class="pt-5 elevation-0 rounded-lg input_text"
                                ></v-select>
                            </template>
                        </v-edit-dialog>
                    </template>

                    <template v-slot:item.phone="{ item }">
                        <div>{{ formatPhoneNumber(item.phone) }}</div>
                    </template>
                    <template v-slot:item.action="{ item }">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" @click="editPartner(item)" icon>
                                    <v-icon>mdi-square-edit-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('edit') }}</span>
                        </v-tooltip>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn v-on="on" :disabled="item.deleted" @click="deletePartner(item)" icon>
                                    <v-icon>mdi-delete-outline</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ item.deleted ? $t('remotely') : $t('delete') }}</span>
                        </v-tooltip>
                    </template>
                </v-data-table>

            </v-col>
        </v-row>

        <v-row>
            <v-col cols="9" class="d-flex justify-start " v-if="!$vuetify.breakpoint.xsOnly">
                <v-select :value="perPage" :items="perPageItems" :disabled="loading"
                          :label="$t('items_per_page')"
                          @input="perPage = options.itemsPerPage = Number($event)"
                          outlined
                          rounded
                          dense
                          class="rounded-lg"
                          persistent-hint
                          hide-details></v-select>
            </v-col>
            <v-col :cols="$vuetify.breakpoint.xsOnly ? 12 : 3" class="d-flex justify-end px-0">
                <v-pagination
                    class="rounded-md"
                    color="primary"
                    v-model="page"
                    :total-visible="totalVisiblePage"
                    :length="pageCount"
                    :disabled="loading"
                ></v-pagination>
            </v-col>
        </v-row>


    </v-container>
</template>
<script>

import {mapGetters} from "vuex"
import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mask} from 'vue-the-mask'

export default {
    name: "Partners",
    directives: {
        mask,
    },
    components: {
        ValidationProvider,
        ValidationObserver
    },
    data() {
        return {
            filter: false,
            totalVisiblePage: 5,
            fab: false,
            phoneRaw: null,
            partner: null,
            email: null,
            partners: [],
            partnerItems: [],
            moderated: 1,
            activeItems: [
                {
                    id: null,
                    name: this.$t('all')
                },
                {
                    id: 1,
                    name: this.$t('yes')
                },
                {
                    id: 0,
                    name: this.$t('no')
                }
            ],
            itemYN: [
                {
                    id: 0,
                    name: this.$t('no'),
                },
                {
                    id: 1,
                    name: this.$t('yes'),
                }
            ],
            sortBy: "name",
            sortDir: true,
            page: 1,
            pageCount: 0,
            perPage: 5,
            totalVisiblePag: 5,
            totalPartners: 0,
            options: {},
            loading: false,
            headers: [
                {
                    text: this.$t('doctor_name'),
                    align: "left",
                    sortable: true,
                    value: "name"
                },
                {
                    text: this.$t('phone'),
                    sortable: true,
                    value: "phone"
                },
                {
                    text: this.$t('doctor_category'),
                    sortable: true,
                    value: "doctor_category"
                },
                {
                    text: this.$t('specialty'),
                    sortable: true,
                    value: "specialty"
                },
                {
                    text: this.$t('active'),
                    align: "center",
                    sortable: true,
                    value: "moderated",
                    width: 110,
                },
                {
                    text: this.$t('duty'),
                    align: "center",
                    sortable: true,
                    value: "duty",
                    width: 120,
                },
                {
                    text: this.$t('actions'),
                    align: "center",
                    value: 'action',
                    sortable: false,
                    width: 120,
                },
            ]
        };
    },
    computed: {
        ...mapGetters(['lang', 'itemsPerPage', 'perPageItems', 'phoneMask']),
        phone: function () {
            return this.changePhone(this.phoneRaw)
        },
        filteredHeaders() {
            return this.headers.filter(h => !h.hide)
        },
    },
    mounted() {
        this.options.itemsPerPage = this.perPage = this.itemsPerPage
    },
    watch: {
        options: {
            handler() {
                this.getPartners()
            },
            deep: false
        }
    },
    methods: {
        toggleFilter() {
            this.filter = !this.filter;
        },
        editPartner(item) {
            this.$router.push({
                name: 'partner.edit',
                params: {
                    id: item.id
                }
            })
        },
        async deletePartner(item) {
            if (confirm(this.$t('delete_doctor'))) {
                var _this = this
                this.loading = true;
                await this.$http
                    .delete(`admin/partner/${item.id}`)
                    .then(res => {
                        this.$toastr.success(this.$t('doctor_has_been_deleted'))
                        this.getPartners()
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('doctor_has_not_been_deleted'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.loading = false
                    })
            }
        },
        async getPartners(type) {
            this.loading = true;
            const {
                sortBy,
                sortDesc,
                page,
                itemsPerPage
            } = this.options;
            let params = {};
            if (sortBy[0] !== undefined) {
                params.sortBy = sortBy[0];
            }
            if (sortDesc[0] !== undefined) {
                params.sortDir = sortDesc[0] ? 'asc' : 'desc';
            }
            if (page !== undefined) {
                if (type === 'search') {
                    params.page = 1
                } else {
                    params.page = page
                }
            }
            if (itemsPerPage !== undefined) {
                params.perPage = itemsPerPage;
            }
            if (this.partner) {
                params.partner = this.partner;
            }
            if (this.phone) {
                params.phone = this.phone;
            }
            if (this.email) {
                params.email = this.email;
            }
            params.moderated = this.moderated

            await this.$http
                .get("admin/partner", {
                    params: params,
                })
                .then(res => {
                    this.partners = res.body.data
                    this.page = res.body.meta.current_page
                    this.totalPartners = res.body.meta.total
                    this.pageCount = res.body.meta.last_page
                })
                .catch(err => {
                    this.partners = []
                    this.totalPartners = 0
                    this.$toastr.error(this.$t('failed_to_get_list_doctors'))
                })
                .finally(end => {
                    this.loading = false
                })
        },
        async setDuty(item) {
            var _this = this
            this.loading = true;
            let params = {};
            if (item.duty) {
                params.duty = 1
            } else {
                params.duty = 0
            }
            await this.$http
                .put(`admin/partner/duty/${item.id}`, params)
                .then(res => {
                    if (params.duty) {
                        this.$toastr.success(this.$t('doctor_is_on_duty'))
                    } else {
                        this.$toastr.success(this.$t('doctor_is_off_duty'))
                    }
                })
                .catch(err => {
                    this.$toastr.error(this.$t('duty_assignment_error'))
                    if (params.duty) {
                        item.duty = false;
                    }
                    if (err && err.body && err.body.message) {
                        for (let prop in err.body.errors) {
                            if (hasOwnProperty.call(err.body.errors, prop)) {
                                if (_this.$refs[prop]) {
                                    _this.$refs[prop].setErrors([
                                        err.body.errors[prop][0]
                                    ])
                                }
                            }
                        }
                        if (!err.body.errors) {
                            this.$toastr.error(err.body.message)
                        }
                    }
                })

                .finally(end => {
                    this.loading = false
                })
        },
    }
}
</script>
