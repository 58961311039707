<template>
    <v-navigation-drawer v-model="drawerShow" mobile-breakpoint="960" height="100%" app>
        <v-list class="py-0 company-logo" color="primary" style="border-radius:0;height:64px;">
            <v-list-item>
                <v-list-item-content>
                    <v-img src="/img/logo.svg" class="logo" max-height="40px" contain></v-img>
                </v-list-item-content>
                <v-list-item-icon v-if="$vuetify.breakpoint.smAndDown">
                    <v-btn small icon @click.stop="navToggle" class="mx-0">
                        <v-icon class="white--text" >mdi-close</v-icon>
                    </v-btn>
                </v-list-item-icon>
            </v-list-item>
        </v-list>
        <v-divider class="my-0"></v-divider>
        <scrollbar ref="scrollbar" :swicher="!$vuetify.breakpoint.smAndDown"
                   :settings="settingsScrollbar" class="inner-scrollbar">
            <v-list class="py-0" dense v-for="(group, index) in items" :key="index">
                <template v-for="item in group">
                    <v-subheader v-if="item.header" v-show="item.visible">{{ $t(item.title) }}</v-subheader>
                    <v-list-group v-else-if="!!item.items" v-show="item.visible" :prepend-icon="item.icon" no-action
                                  :key="item.title" v-model="item.active" color="icon_menu">
                        <template v-slot:activator>
                            <v-list-item-content class="nav-bar-content">
                                <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </template>
                        <v-list-item v-for="subItem in item.items" :key="subItem.title" v-show="subItem.visible"
                                     @click.native="subItem.action ? subItem.action() : false" :to="subItem.to" ripple
                                     exact-active-class="my_menu"
                                     :exact="subItem.exact !== undefined ? subItem.exact : true">
                            <v-list-item-icon>
                                <v-icon color="icon_menu">{{ subItem.icon }}</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content class="pl-2 nav-bar-content">
                                <v-list-item-title>{{ $t(subItem.title) }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list-group>
                    <v-list-item v-else-if="!item.header" v-show="item.visible"
                                 @click.native="item.action ? item.action() : false"
                                 href="javascript:void(0)" :to="item.to" ripple

                                 exact-active-class="my_menu"
                                 :exact="item.exact !== undefined ? item.exact : true" :key="item.title">
                        <v-list-item-icon>
                            <v-icon color="icon_menu">{{ item.icon }}</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content class="nav-bar-content">
                            <v-list-item-title>{{ $t(item.title) }}</v-list-item-title>
                        </v-list-item-content>
                    </v-list-item>
                </template>
            </v-list>
        </scrollbar>
    </v-navigation-drawer>
</template>
<script>

import Scrollbar from './Scrollbar'

export default {
    name: "AppSidebar",
    components: {
        Scrollbar,
    },
    inject: ['forceRerender'],
    props: {
        drawer: Boolean
    },
    data() {
        return {
            isMini: null,
            items: [],
            settingsScrollbar: {
                suppressScrollX: true,
            },
        }
    },
    computed: {
        drawerShow: {
            get: function () {
                return this.drawer
            },
            set: function (state) {
                if (state !== this.drawer) {
                    this.navToggle()
                }
            }
        },
        routePath() {
            return this.$route.path
        },
    },
    watch: {
        routePath(newVal, oldVal) {
            if (newVal !== oldVal) {
                this.navigation()
            }
        },
    },
    mounted() {
        this.navigation()
    },
    methods: {
        navToggle() {
            this.$emit("nav-toggle")
        },
        navigation() {
            this.items = [
                [
                    {
                        title: 'menu_general',
                        header: true,
                        visible: this.$auth.check(),
                    },
                    {
                        title: 'menu_dashboard',
                        icon: "mdi-view-dashboard mdi-18px",
                        to: {name: "dashboard"},
                        action: this.forceRerender,
                        visible: this.$auth.check(),
                        exact: false
                    },
                    {
                        title: 'menu_billing',
                        header: true,
                        visible: this.can(['administrator', 'manager','clinic']),
                    },
                    {
                        title: 'menu_emergency_calls',
                        icon: "mdi-heart-pulse mdi-18px",
                        to: {name: "emergency_call.status", params: {status: 'new'}},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_emergency_call_in_progress',
                        icon: "mdi-heart-settings mdi-18px",
                        to: {name: "emergency_call.status", params: {status: 'in_progress'}},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','clinic']),
                        exact: false
                    },
                    {
                        title: 'menu_emergency_call_completed',
                        icon: "mdi-heart-plus mdi-18px",
                        to: {name: "emergency_call.status", params: {status: 'completed'}},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','clinic']),
                        exact: false
                    },
                    {
                        title: 'menu_emergency_call_canceled',
                        icon: "mdi-heart-remove mdi-18px",
                        to: {name: "emergency_call.status", params: {status: 'canceled'}},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager','clinic']),
                        exact: false
                    },
                    {
                        title: 'menu_patients',
                        icon: "mdi-account-group-outline mdi-18px",
                        to: {name: "patient"},
                        action: this.forceRerender,
                        visible: this.can(['administrator', 'manager', 'accountant']),
                        exact: false
                    },

                    {
                        title: 'menu_administration',
                        header: true,
                        visible: this.can(['administrator','clinic', 'manager']),
                    },
                    {
                        title: this.$t('menu_company'),
                        icon: "mdi-home-plus-outline mdi-18px",
                        to: {name: "company_clinic"},
                        action: this.forceRerender,
                        visible: this.can(['clinic']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_companies'),
                        icon: "mdi-page-layout-header-footer mdi-18px",
                        to: {name: "company"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_doctors',
                        icon: "mdi-doctor mdi-18px",
                        to: {name: 'partner'},
                        action: this.forceRerender,
                        visible: this.can(['administrator','clinic', 'manager']),
                        exact: false
                    },
                    {
                        title: 'menu_users',
                        icon: 'mdi-account-group mdi-18px',
                        visible: this.can(['administrator']),
                        exact: false,
                        active: ['/administrator', '/clinic', '/client'].arrayStartsWith(this.routePath),
                        items: [
                            {
                                title: 'menu_administrators',
                                icon: "mdi-account-tie mdi-18px",
                                to: {name: 'administrator'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            },
                            {
                                title: 'menu_clinics',
                                icon: "mdi-home-plus-outline mdi-18px",
                                to: {name: 'clinic'},
                                action: this.forceRerender,
                                visible: this.can(['administrator']),
                                exact: false
                            }
                        ]
                    },
                    {
                        title: 'menu_faq',
                        icon: "mdi-ticket-percent-outline mdi-18px",
                        to: {name: 'faq'},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },

                    {
                        title: this.$t('menu_pages'),
                        icon: "mdi-page-layout-header-footer mdi-18px",
                        to: {name: "page"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_news',
                        icon: "mdi-newspaper-variant-outline mdi-18px",
                        to: {name: 'news'},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },

                    {
                        title: 'menu_handbooks',
                        header: true,
                        visible: this.can(['administrator']),
                    },

                    {
                        title: 'menu_sexes',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "sex"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_countries'),
                        icon: "mdi-web mdi-18px",
                        to: {name: "country"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: this.$t('menu_cities'),
                        icon: "mdi-city-variant mdi-18px",
                        to: {name: "city"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_transport_types',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "transport_type"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_symptom_categories',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "symptom_category"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_symptoms',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "symptom"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_crews',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "crew"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_type_crew_members',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "type_crew_member"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_crew_members',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "crew_member"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_medications',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "medication"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_provided_treatments',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "provided_treatment"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_prescriptions',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "prescription"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_specialties',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "specialty"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                    {
                        title: 'menu_doctor_categories',
                        icon: "mdi-gender-male-female mdi-18px",
                        to: {name: "doctor_category"},
                        action: this.forceRerender,
                        visible: this.can(['administrator']),
                        exact: false
                    },
                ]
            ];
        }
    }
};

</script>

<style scoped>
.logo {
    filter: invert(100%) brightness(200%);
}
</style>
